import React, { Component } from 'react';

export const routes = [
  {
    path: '/',
    root: true,
    id: 'index',
    component: React.lazy(() => import('src/views/Login/Google')),
    children: [
      {
        path: '/trade',
        id: 'trade',
        component: React.lazy(() => import('src/views/Trade'))
      },
      {
        path: '/friends',
        id: 'friends',
        component: React.lazy(() => import('src/views/Friends'))
      },
      {
        path: '/withdraw',
        id: 'withdraw',
        component: React.lazy(() => import('src/views/Wallet'))
      },
      {
        path: '/deposit',
        id: 'deposit',
        component: React.lazy(() => import('src/views/Deposit'))
      },
      // {
      //   path: '*',
      //   component: React.lazy(() => import('@/components/404')),
      // },
    ],
  },
]